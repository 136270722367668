<template>
    <div class="d-flex justify-center align-center flex-column">
        <v-progress-linear :value="cpuUsage" :color="cpuColor" height="5"></v-progress-linear>
        <div class="caption">{{ cpuLimit }} vCPU</div>
        <v-progress-linear :value="memoryUsage" :color="memoryColor" height="5"></v-progress-linear>
        <div class="caption">{{ memoryLimitAbs }} GB</div>
    </div>
</template>

<script>
export default {
    props: ['currentApp'],
    computed: {
        memoryLimitAbs() {
            if (this.$props.currentApp && this.$props.currentApp.limit_memory !== 'N/A') {
                return (parseFloat(this.$props.currentApp.limit_memory.replace('M', '')) / 1000).toFixed(0)
            }
            return 0
        },
        memoryUsage() {
            if (this.$props.currentApp && this.$props.currentApp.limit_memory !== 'N/A' && this.$props.currentApp.current_memory !== 'N/A') {
                const limitMemory = parseFloat(this.$props.currentApp.limit_memory.replace('M', ''))
                if (limitMemory <= 0) {
                    return 0
                }
                const currentMemory = parseFloat(this.$props.currentApp.current_memory.replace('M', ''))
                return Math.round((100 * currentMemory) / limitMemory)
            }
            return 0
        },
        memoryColor() {
            if (this.memoryUsage < 90) {
                return 'info'
            }
            return 'error'
        },
        cpuUsage() {
            if (this.$props.currentApp && this.$props.currentApp.limit_cpu !== 'N/A' && this.$props.currentApp.current_cpu !== 'N/A') {
                const limitCPU = parseFloat(this.$props.currentApp.limit_cpu.replace('m', ''))
                if (limitCPU <= 0) {
                    return 0
                }
                const currentCPU = parseFloat(this.$props.currentApp.current_cpu.replace('m', ''))
                return Math.round((100 * currentCPU) / limitCPU)
            }
            return 0
        },
        cpuColor() {
            if (this.cpuUsage < 90) {
                return 'success'
            }
            return 'error'
        },
        memoryLimit() {
            if (this.$props.currentApp) {
                return this.$props.currentApp.limit_memory
            }
            return 0
        },
        cpuLimit() {
            if (this.$props.currentApp && this.$props.currentApp.limit_cpu !== 'N/A') {
                return parseFloat(this.$props.currentApp.limit_cpu.replace('m', '')) / 1000
            }
            return 0
        }
    },
    mounted() {
        this.$emit('memoryLimit', { value: this.memoryLimit })
    },
    watch: {
        memoryUsage: {
            immediate: true,
            handler: function(to, from) {
                if (to > 90 && from < 90) {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'You are nearing the memory limit where the application will be restarted!',
                        snackBarIcon: 'error',
                        snackBarTimeout: 10000
                    })
                }
            }
        },
        memoryLimit: function(nextVal) {
            this.$emit('memoryLimit', { value: nextVal })
        }
    }
}
</script>
