var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-center align-center flex-column" },
    [
      _c("v-progress-linear", {
        attrs: { value: _vm.cpuUsage, color: _vm.cpuColor, height: "5" }
      }),
      _c("div", { staticClass: "caption" }, [
        _vm._v(_vm._s(_vm.cpuLimit) + " vCPU")
      ]),
      _c("v-progress-linear", {
        attrs: { value: _vm.memoryUsage, color: _vm.memoryColor, height: "5" }
      }),
      _c("div", { staticClass: "caption" }, [
        _vm._v(_vm._s(_vm.memoryLimitAbs) + " GB")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }